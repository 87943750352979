import React from "react";
import { Col, Row, NavLink } from "reactstrap";

import { Main } from "./style";
import { Header } from "../../components";
import badge from '../../assets/image/badge.png';
import logo from '../../assets/image/logo.png';
import iphone from '../../assets/image/iphone.png';
import ios from '../../assets/image/ios.png'
import android from '../../assets/image/android.png'
import botlleCap from '../../assets/image/bottle-cap.png'
import botlleLogo from '../../assets/image/bottle-logo.png'

const Home = () => {
    return (
        <>
            <Main>
                <Row className="p-0 m-0 header">
                    <Header />
                </Row>
                <Row className="p-0 m-0 second-row">
                    <Col sm={12} md={12} lg={4} xl={4} className="p-0 m-0">
                        <div className="left-wrapper">
                            <img className="header-icon-middle-first" src={logo} alt="" />
                            <img className="header-logo" src={badge} alt="" />
                            <h1 className="text-white description-wrapper-1">This fog is very thick.</h1>
                        </div>

                        <div className="left-wrapper-2">
                            <NavLink className="p-0 m-0" href="https://play.google.com/store/apps/details?id=com.ideofuzion.whaddaya">
                                <img className="header-logo-1" src={android} alt="" />
                            </NavLink>
                            <NavLink className="p-0 m-0" href="https://apps.apple.com/ca/app/whaddaya-app/id1572396598">
                                <img className="header-logo-1 mt-4" src={ios} alt="" />
                            </NavLink>
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={4} xl={4} className="p-0 m-0">
                        <div className="left-wrapper-1">
                            <div className="d-flex justify-content-end align-items-center">
                                <img className="header-logo-cap" src={botlleCap} alt="" />
                            </div>
                            <div>
                                <img className="header-logo-bottle" src={botlleLogo} alt="" />
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <img className="header-icon-middle" src={logo} alt="" />
                            <h1 className="text-white description-wrapper">Light-hearted humour from
                                the shores of Newfoundland
                                and Labrador. The world's first Newfoundland dialect sound
                                board and translator.</h1>
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={4} xl={4} className="p-0 m-0 d-flex">
                        <img className="header-icon-last" src={iphone} alt="" />
                    </Col>
                </Row>
            </Main>
        </>
    )
}

export default Home;