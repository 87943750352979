import styled from "styled-components"

export const Main = styled.div`
  .container-style{
    min-height:100px;
    align-items:center;
  }
  .header-icon{
    width:50px !important;
    height:50px !important;
    margin:0 6px;
  }
  .my-link{
    font-size:35px;
    margin: 0px 16px;
    width: max-content;
    padding:0;
    font-family: 'Special Elite';
  }
  .nav-parent{
    padding: 20px 0;
  }


  @media (max-width: 768px) {
    .nav-parent{
      margin: 20px 0px;
      padding:0;
      justify-content: flex-start;
      align-items: flex-start !important;
    }
  }
`