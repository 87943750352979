import styled from "styled-components"

import background from '../../assets/image/background.png'
export const Main = styled.div`

min-height:100vh;
background-size: cover;
background-image:url(${background}); 
.header-logo{
  width: 420px;
  margin-top: -30px;
}
.header-logo-1{
  width:-webkit-fill-available;
  width:328px;
}
.header-icon-middle-first{
  display:none
}
.header-icon-middle{
  width:-webkit-fill-available;
  margin-bottom: 25px;
}

.left-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // height:100%;    
}
.left-wrapper-2{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;     
  height:160px;
  margin-top:20%;
}
.content-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top:80px;
}
.header-icon-last{
  width:100%;
  max-height: 100vh;
  // height: calc(100vh - 100px);
}
.description-wrapper-1{
  display:none;
}
.description-wrapper{
  padding: 171px 0px 0 0;
  // margin-top: 30% !important;
  font-family: 'Special Elite';
  font-size:42px;
  text-shadow: 5px 4px 8px black;
}
.left-wrapper-1{
  display:none;
}
@media (max-width: 1024px) {
  .description-wrapper{
    padding: 0;
    margin-top: 100px !important;
    font-family: 'Special Elite';
    font-size:34px;
  }
  .header-logo{
    width: 300px;
    margin-top: -30px;
  }
  .header-logo-1{
    width:-webkit-fill-available;
    width:228px;
  }
  .header-icon-last{
    width:100%;
    min-height: 100vh;
    // height: calc(100vh - 100px);
  }
}
@media (max-width: 768px) {
  .header-icon-middle{
    width:330px;
  }
  .header-logo {
    width: 280px;
    margin-top: -30px;
  }
  .header-logo-1{
    width:240px;
  } 
  .description-wrapper{
    padding: 0 40px;
    margin-top: 100px !important;
    font-family: 'Special Elite';
  }
  .header-icon-middle-first{
    display:block;
    width:-webkit-fill-available;
    margin:0 100px 50px 100px;
  }
  .header-icon-middle{
    display:none;
  }
  .description-wrapper-1{
    display:block;
    margin: 30px 0;
  }
  .left-wrapper-2{
    margin-top:10px;
  }
 
  .header-icon-middle-first{
    display:block;
    width:-webkit-fill-available;
    margin:50px 50px 50px 50px;
  }
}
@media (max-width: 600px) {
  .left-wrapper-1{
    display:block;
  }
  .content-wrapper{
    display:none;
  }
  .header-icon-last{
    display:none;
  }
  .left-wrapper-2{
    display:none;
  }
  .header-logo-cap{
    width: 200px;
    margin-right: 50px;
    margin-bottom: -90px;
    margin-top: 100px;
  }
  .header-logo-bottle{
    width:250px;
  }
  .header{
    display:none;
  }
}
`
