import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Container } from 'reactstrap';
import fbImg from '../../assets/image/fbImg.png'
import mail from '../../assets/image/mail.png'
import pdf from '../../assets/image/aboutus.pdf'
import { Main } from './style';
const Header = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <Main>
      <Container className="container-style">
        <Navbar color="faded" expand="sm" dark>
          <NavbarBrand href="/"></NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse isOpen={!collapsed} navbar className="justify-content-end align-items-center">
            <Nav navbar className="align-items-center nav-parent d-flex">
              <NavItem>
                <NavLink href="https://apps.apple.com/us/app/whaddaya-app/id1572396598" target="_blank" className="text-white my-link">Download</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={pdf} target="_blank" className="text-white my-link">About Us</NavLink>
              </NavItem>
              <NavItem className="flex-row d-flex">
                <NavLink href="https://www.facebook.com/whaddayaapp" target="_blank" className="p-0 m-0">
                  <img className="header-icon " src={fbImg} alt="" />
                </NavLink>
                <NavLink href="mailto:info@whaddayaapp.com" className="p-0 m-0">
                  <img className="header-icon" src={mail} alt="" />
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </Main>
  );
}

export default Header;